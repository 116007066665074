import { requestData } from '@/api';
import store from '@/store';
// 埋点接口
/**
 * @param buryingPointName 对应函数名
 * @param params 参数
 * @returns 根据key 返回对应接口
 */
export function buryingPointName(buryingPointName, params) {
    const PointfunObj = {
        // 基本信息埋点               
        getBasicInfoPoint: (params) => requestData('/baseInfoPoint', params).then(() => {
            if (store.state.settings.work_type == 1 || store.state.settings.work_type == 2) {
                console.log(params, '这是工作信息的埋点');
                PointfunObj.workPoint(params);
            }
            PointfunObj.contactPoint(params);
        }),
        // 职业信息埋点
        workPoint: (params) => requestData('/workPoint', params),
        contactPoint: (params) => requestData('/contactPoint', params),
        // 企业信息埋点
        basicEnterprisePoint: (params) => requestData('/basicEnterprisePoint', params),
        // 上班信息埋点
        basicWorkerPoint: (params) => requestData('/basicWorkerPoint', params),
        // 学生信息埋点
        basicStudentPoint: (params) => requestData('/basicStudentPoint', params),
        // 银行卡埋点
        getBankPoint: (params) => requestData('/bankCard', params)
    };
    return PointfunObj[buryingPointName](params);
}
