"use strict";
module.exports = {
    // 基本信息提交参数映射
    baseInfoSubmit: {
        gender: 'yFxEeeWT',
        age: 'yNINEvlX',
        birthday: 'Ukuvelenga',
        card_type: 'card_Umusango_atemwa_Ibumba',
        card_number: 'FzGgWGLi',
        email: 'Umufikila_inkalata',
        education: 'Ifya_kusambilila',
        whatsapp: 'whatsapp',
        marry_status: 'Marital_status',
        useof_funds: 'ukulemba_inkalata',
        dwell_provinces: 'provinces',
        dwell_city: 'city',
        dwell_county: 'county',
        dwell_address: 'address',
        work_type: 'work_Umusango_atemwa_Ibumba',
        submit_tag: 'tag',
        contact_json: 'Ukubika_nabantu_abengi',
        years_working: 'Imisango_yakusalapo',
        manage_duration: '',
        profession: 'industry',
        monthly_income: 'monthly_income',
        company_name: 'corporate_name',
        company_phone: 'corporate_phone',
        company_provinces: 'corporate_provinces',
        company_city: 'corporate_city',
        company_county: 'corporate_county',
        company_address: 'corporate_address',
        salary_way: 'Payroll_method',
        pay_day: 'Payday' // 发薪日
    },
    // 取现方式映射
    withdrawalSubmit: {
        payout_type: 'Umusango_Ibumba',
        channel_id: 'pai_channel',
        channel_name: 'Full_name',
        card_account: 'Apa_kwingilila',
        confirm_account: 'confirm_account',
        bank_code: 'bankCode',
        lastName: 'ryFunyeb',
        firstName: 'zZMQLYCT',
        middleName: 'NkGUMcUp'
    }
};
