<template>
  <div>
    <van-row :class="stepNum" class="step-box">
      <van-col v-for="(item,index) in dataList" :key="index" class="step-item" span="6">
        <span :class="'icon'+index" class="icon-box"></span>
        <span :class="'remark'+index" class="remark-box">{{ item.remark }}</span>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  // first-step second-step third-step fouth-step
  props: {
    stepNum: {
      type: [String, Number],
      default: () => {
        return 'first-step'
      }
    },
    stepLeng: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      dataList: [
        {
          isWrit: '',
          iconClass: '',
          writIconClass: '',
          noWritIconClass: '',
          remark: 'Información básica'
        },
        {
          isWrit: '',
          iconClass: '',
          writIconClass: '',
          noWritIconClass: '',
          remark: 'Información de contacto'
        },
        {
          isWrit: '',
          iconClass: '',
          writIconClass: '',
          noWritIconClass: '',
          remark: 'Información bancaria'
        },
        {
          isWrit: '',
          iconClass: '',
          writIconClass: '',
          noWritIconClass: '',
          remark: 'Información de identidad'
        }
      ]
    }
  },
  created() {

  }
}
</script>
<style lang="scss" scoped>
.step-box{
  .icon-box{
    display: block;
    width: 82px;
    height: 82px;
    position: relative;
    left: 50%;
    margin-left: -41px;
  }
  .remark-box{
    font-size: 20px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
    // padding-right: 60px;
    display: block;
    text-align: center;
  }

  :deep(.step-item){
    width: 126px;
    margin-right: 60px;
  }
  :deep(.step-item):last-child{
    margin-right: 0;
  }
}

.first-step{
  .remark0{
    color: #333333;
  }
  .icon0{
    background: url(../assets/images/baseInfo/steps/first-step-active.png) no-repeat center center;
    background-size: 100%;
    margin-bottom: 9px;
    &::after{
      content: '';
      position: absolute;
      width: 71px;
      height: 1px;
      right: -88px;
      border-top: 2px dashed #FAD32C;
      top: 50%;
      margin-top: -1px;
    }
  }
  .icon1{
    background: url(../assets/images/baseInfo/steps/second-step-no.png) no-repeat center center;
    background-size: 68px 68px;
    margin-bottom: 16px;
    &::after{
      content: '';
      position: absolute;
      width: 71px;
      height: 1px;
      right: -88px;
      border-top: 2px dashed #DFDFDF;
      top: 50%;
      margin-top: -1px;
    }
  }
  .icon2{
    background: url(../assets/images/baseInfo/steps/third-step-no.png) no-repeat center center;
    background-size: 68px 68px;
    margin-bottom: 16px;
    &::after{
      content: '';
      position: absolute;
      width: 71px;
      height: 1px;
      right: -88px;
      border-top: 2px dashed #DFDFDF;
      top: 50%;
      margin-top: -1px;
    }
  }
  .icon3{
    background: url(../assets/images/baseInfo/steps/fouth-step-no.png) no-repeat center center;
    background-size: 68px 68px;
    margin-bottom: 16px;
  }

}
.second-step{
  .remark0,.remark1{
    color: #333333;
  }
.icon0{
    background: url(../assets/images/baseInfo/steps/first-step-ready.png) no-repeat center center;
    background-size: 68px 68px;
    margin-bottom: 16px;
    &::after{
      content: '';
      position: absolute;
      width: 71px;
      height: 1px;
      right: -88px;
      border-top: 2px dashed #FAD32C;
      top: 50%;
      margin-top: -1px;
    }
  }
  .icon1{
    background: url(../assets/images/baseInfo/steps/second-step-active.png) no-repeat center center;
    background-size: 100%;
    margin-bottom: 9px;
    &::after{
      content: '';
      position: absolute;
      width: 71px;
      height: 1px;
      right: -88px;
      border-top: 2px dashed #FAD32C;
      top: 50%;
      margin-top: -1px;
    }
  }
  .icon2{
    background: url(../assets/images/baseInfo/steps/third-step-no.png) no-repeat center center;
    background-size: 68px 68px;
    margin-bottom: 16px;
    &::after{
      content: '';
      position: absolute;
      width: 71px;
      height: 1px;
      right: -88px;
      border-top: 2px dashed #DFDFDF;
      top: 50%;
      margin-top: -1px;
   }
  }
  .icon3{
    background: url(../assets/images/baseInfo/steps/fouth-step-no.png) no-repeat center center;
    background-size: 68px 68px;
    margin-bottom: 16px;
  }
}
.third-step{
  .remark0,.remark1,.remark2{
    color: #333333;
  }
.icon0{
    background: url(../assets/images/baseInfo/steps/first-step-ready.png) no-repeat center center;
    background-size: 68px 68px;
    margin-bottom: 16px;
    &::after{
      content: '';
      position: absolute;
      width: 71px;
      height: 1px;
      right: -88px;
      border-top: 2px dashed #FAD32C;
      top: 50%;
      margin-top: -1px;
    }
  }
  .icon1{
    background: url(../assets/images/baseInfo/steps/second-step-ready.png) no-repeat center center;
    background-size: 68px 68px;
    margin-bottom: 16px;
    &::after{
      content: '';
      position: absolute;
      width: 71px;
      height: 1px;
      right: -88px;
      border-top: 2px dashed #FAD32C;
      top: 50%;
      margin-top: -1px;
    }
  }
  .icon2{
    background: url(../assets/images/baseInfo/steps/third-step-active.png) no-repeat center center;
    background-size: 100%;
    margin-bottom: 9px;
    &::after{
      content: '';
      position: absolute;
      width: 71px;
      height: 1px;
      right: -88px;
      border-top: 2px dashed #DFDFDF;
      top: 50%;
      margin-top: -1px;
    }
  }
  .icon3{
    background: url(../assets/images/baseInfo/steps/fouth-step-no.png) no-repeat center center;
    background-size: 68px 68px;
    margin-bottom: 16px;
  }
}
.fouth-step{
.remark0,.remark1,.remark2,.remark3{
  color: #333333;
  letter-spacing: -1px;
}
.icon0{
    background: url(../assets/images/baseInfo/steps/first-step-ready.png) no-repeat center center;
    background-size: 68px 68px;
    margin-bottom: 16px;
    &::after{
      content: '';
      position: absolute;
      width: 71px;
      height: 1px;
      right: -88px;
      border-top: 2px dashed #FAD32C;
      top: 50%;
      margin-top: -1px;
    }
  }
  .icon1{
    background: url(../assets/images/baseInfo/steps/second-step-ready.png) no-repeat center center;
    background-size: 68px 68px;
    margin-bottom: 16px;
    &::after{
      content: '';
      position: absolute;
      width: 71px;
      height: 1px;
      right: -88px;
      border-top: 2px dashed #FAD32C;
      top: 50%;
      margin-top: -1px;
    }
  }
  .icon2{
    background: url(../assets/images/baseInfo/steps/third-step-ready.png) no-repeat center center;
    background-size: 68px 68px;
    margin-bottom: 16px;
    &::after{
      content: '';
      position: absolute;
      width: 71px;
      height: 1px;
      right: -88px;
      border-top: 2px dashed #FAD32C;
      top: 50%;
      margin-top: -1px;
    }
  }
  .icon3{
    background: url(../assets/images/baseInfo/steps/fouth-step-active.png) no-repeat center center;
    background-size: 100%;
    margin-bottom: 9px;
  }
}
.remark0,.remark1,.remark2,.remark3{
  letter-spacing: -1px;
}
</style>
