<template>
  <div class="select-box" @click="disabledTips">
    <van-field v-model="nameVal" clickable :name="name" :rules="[{ required: true, message: $t(placeholder) }]" :class="[String(nameVal).length?'has-val':'']" class="form-item is-select" readonly :placeholder="$t(placeholder)" @click="visbile=true" />
    <popu-action v-if="selectType==='popu'" v-model="emitVal" :visbile.sync="visbile" name-string="name" :title="$t(placeholder)" :data-list="dataList" @open="$emit('update:isFocusPoint', true)" @close="$emit('update:isFocusPoint', false)" />
    <van-dropdown-menu
      v-else
      ref="dropdownMenu"
      active-color="#6D42EFFF"
    >
      <van-dropdown-item
        :ref="'dropdownItem_'+placeholder"
        v-model="emitVal"
        :options="selectList"
        :disabled="disabled"
        @open="dropdownOpen"
        @close="dropdownClose"
        @opened="opened"
      />
    </van-dropdown-menu>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: [String, Number],
      default: ''
    },
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    },
    options: {
      type: Object,
      default: () => {
        return {
          key: 'name',
          val: 'val'
        }
      }
    },
    nameOrVal: {
      type: String,
      default: function() {
        return this.options.key
      }
    },
    /**
     *selectType select|popu
     */
    selectType: {
      type: String,
      default: 'select'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visbile: false,
      emitVal: '',
      nameVal: '',
      id: '',
      emitValData: ''
    }
  },
  computed: {
    selectList() {
      if (this.selectType === 'select') {
        this.dataList.forEach(item => {
          item.value = item[this.options.val]
          item.text = item[this.options.key]
        })
      }
      return this.dataList
    }
  },
  watch: {
    value: {
      handler() {
        if (this.options.key === this.nameOrVal && this.value) {
          const item = this.selectList.find(item => { return item[this.options.key] + '' === this.value + '' })
          if (!item) return
          this.emitVal = item[this.options.val]
        } else {
          this.emitVal = this.value
        }
      },
      immediate: true
    },
    visbile: {
      handler(val) {
        if (!val) {
          this.$emit('input', this.emitValData)
        }
      },
      immediate: true
    },
    emitVal: {
      handler() {
        const item = this.selectList.find(item => { return item.value + '' === this.emitVal + '' })
        if (item) {
          this.nameVal = item[this.options.key]
          this.emitValData = item[this.nameOrVal]
          this.id = item[this.options.val]
        } else {
          this.nameVal = ''
          this.id = ''
        }
      },
      immediate: true
    }
  },
  methods: {
    dropdownOpen() {
      this.visbile = true
      this.$emit('update:isFocusPoint', true)
    },
    async dropdownClose() {
      await (this.visbile = false)
      await this.$emit('update:isFocusPoint', false)
      // await this.$emit('update:itemId', this.id)
    },

    opened() {
      document.removeEventListener('touchstart', this.$refs['dropdownItem_' + this.placeholder].$children[0].touchStart)
      document.removeEventListener('touchmove', this.$refs['dropdownItem_' + this.placeholder].$children[0].onTouchMove)
    },

    disabledTips() {
      this.$emit('click')
      // if (this.disabled) {
      //   // this.$toast.fail(this.$t('baseInfo.perfectInfo'))
      // }
    }
  }

}
</script>
<style lang="scss" scoped>
.select-box{
  position: relative;
}
:deep(.van-dropdown-menu){
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
:deep(.van-dropdown-menu__bar){
  height: 100%;
  opacity: 0;
}
:deep(.van-dropdown-item){
  // left: 40px;
  // right: 40px;
  .van-cell{
    height: 78px;
    line-height: 78px;
    font-size: 26px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #333333;
    padding: 0 32px;
    &::after{
      left: 32px;
      right: 32px;
      border-color: #EEEEEE;
    }
  }
}

:deep(.van-dropdown-item__content){
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 16px;
  left: 40px;
  right: 40px;
  width: auto;
  margin-top: 16px;
  .van-cell__title{
    color: inherit;
    text-align: center;
  }
  .van-cell__value{
    display: none;
  }
}
:deep(.van-overlay){
  background: rgba(0,0,0,0);
}
</style>
