import { buryingPointName } from '@/api/axios/buryingPoint'
import { onVisibilityChange } from '@/utils'
export default {
  watch: {
    watchDataPoint: {
      handler: function(n, o) {
        this.isDifferent(n, o)
      },
      deep: true
    },
    isFocusPoint: {
      handler() {
        if (this.isFocusPoint) {
          this.startTime = this.getnewtime()
          console.log('开始')
        } else {
          console.log('结束')
          if (this.hasValChange) this.setBuryingPointParamsSubmit()
        }
      }
    },
    isSubMitPoint: {
      handler() {
        if (this.isSubMitPoint) this.subMitPoint()
      }
    }
  },
  data() {
    return {
      buryingPointParams: {},
      firstDuration: '_first', // 首次时长后缀
      updatecount: '_updatecount', // 次数后缀
      duration: '_duration', // 总时长后缀
      clickNum: '_num', // 点击次数后缀
      isFocusPoint: false, // true 开始计时 false 计时结束 控制该字段即可进行获取 埋点参数
      timerItem: '', // 字段key
      hasValChange: false, // 值是否发生了变化
      timeLong: 0, // 当次时长
      startTime: this.getnewtime(), // 初始化时间戳
      pagestopTime: this.getnewtime(), // 页面停留时长
      isSubMitPoint: false, // 是否提交埋点数据
      backend_num: 0, // 返回后台次数
      backend_duration: 0 // 返回后台总时长
    }
  },
  computed: {
    watchDataPoint() {
      return JSON.parse(JSON.stringify(this.dataForm))
    }
  },
  created() {
    let buryingPointParams = JSON.parse(localStorage.getItem('buryingPointParams'))
    if(buryingPointParams){
      this.buryingPointParams = buryingPointParams
      console.log(this.buryingPointParams,'初始化埋点字段缓存')
    }else{
      this.setBuryingPointParams()
    }
    this.onVisibilityChange()
  },

  mounted() {
    this.$nextTick(() => {
      this.listenInput()
    })
  },

  methods: {
    // 设置埋点参数
    setBuryingPointParams() {
      Object.keys(this.dataForm).forEach(item => {
        if (item === 'contact_json') {
          this.setContact(item)
        } else {
          this.buryingPointParams[item] = { [item + this.updatecount]: 0, [item + this.duration]: 0, [item + this.firstDuration]: 0, [item + this.clickNum]: 0 }
        }
      })
      console.log(this.buryingPointParams,'初始化埋点字段创建')
    },

    // 联系人设置埋点
    setContact(item) {
      this.dataForm[item].forEach((item, index) => {
        const key = this.getIndexText(index + 1)
        Object.keys(item).forEach(items => {
          this.buryingPointParams[items + key] = { [items + key + this.updatecount]: 0, [items + key + this.duration]: 0, [items + key + this.firstDuration]: 0 , [items + key + this.clickNum]: 0}
        })
      })
      console.log(this.buryingPointParams,'打印通讯录buryingPointParams')
    },

    getIndexText(key) {
      const kv = {
        1: '_one',
        2: '_two',
        3: '_three'
      }
      return kv[key]
    },

    // 监听字段
    isDifferent(n, o) {
      let itemName = []
      Object.keys(n).forEach(nItem => {
        Object.keys(o).forEach(oItem => {
          if (nItem === oItem) {
            if (JSON.stringify(n[nItem]) !== JSON.stringify(o[oItem]))itemName.push(nItem)
          }
        })
      })
      itemName = Array.from(new Set(itemName))
      this.executeFn(itemName, n, o)
    },

    // 设置次数和时间
    executeFn(itemName, n, o) {
      itemName.forEach(item => {
        // 页面回显 不需要埋点操作 如需要把条件判断放开即可
        if (itemName.length === 1 || itemName.includes('dwell_provinces') || itemName.includes('company_provinces') ) {
          // this.setFrequency(item, n, o) // 根据埋点规则可设置
          if (Array.isArray(n[item])) { // 对象类的日后用到再添加
            const string = this.getChangeStr(n, o, item) || ''
            if (!string) { throw new Error('buryingPoint.js 115行 没取到key值,检查一下数组结构') }
            this.setDuration(string, n, o)
          } else {
            this.setDuration(item, n, o)
          }
        }
      })
    },

    // 获取联系人字段
    getChangeStr(n, o, item) {
      for (let i = 0; i < n[item].length; i++) {
        const difItem = JSON.stringify(n[item][i]) !== JSON.stringify(o[item][i])
        if (difItem) {
          const keysList = Object.keys(n[item][i])
          return this.getChangeObj(keysList, i, n[item][i], o[item][i])
        }
      }
    },

    // 获取更改的key
    getChangeObj(keysList, i, nItem, oItem) {
      for (let j = 0; j < keysList.length; j++) {
        if (nItem[keysList[j]] !== oItem[keysList[j]]) {
          return keysList[j] + this.getIndexText(i + 1)
        }
      }
    },

    // 设置输入框修改次数
    setFrequency(item, n, o) {
      const keys = this.buryingPointParams[item][item + this.updatecount] * 1
      if (o[item] === '' && n[item]) {
        this.buryingPointParams[item][item + this.updatecount] = keys + 1
      }
    },

    // 时间
    async setDuration(item, n, o) {
      this.hasValChange = true
      if (this.timerItem !== item) { this.timerItem = item }
      if (!this.isFocusPoint) { // 针对点击类型的至少1s
        this.isFocusPoint = await true
        this.isFocusPoint = await false
      }
    },

    // 输入框类埋点操作
    listenInput() {
      const input = document.getElementsByTagName('input')
      const _t = this
      for (let i = 0; i < input.length; i++) {
        input[i].addEventListener('focus', function() {
          _t.isFocusPoint = true
        })
        input[i].addEventListener('blur', function() {
          _t.isFocusPoint = false
        })
      }

      // const clickNode = document.getElementsByClassName('click-num')
      // for (let i = 0; i < input.length; i++) {
      //   clickNode[i].addEventListener('click', function(res,i) {
      //     console.log(this,res,i)
      //   },false)
      // }
    },

    // 后台切换
    onVisibilityChange() {
      const _t = this
      onVisibilityChange(function(val) {
        if (val) {
          _t.backend_num += 1
          _t.backend_duration += _t.getinterval(_t.getnewtime(), _t.startTime)
        } else {
          _t.startTime = _t.getnewtime()
        }
      })
    },

    // 设置时间 次数 赋值
    setBuryingPointParamsSubmit() {
      this.timeLong = this.getinterval(this.getnewtime(), this.startTime)
      if (!this.timeLong) this.timeLong = 1 // 针对点击类型的至少1s

      if (this.timerItem && this.hasValChange) {
        const firstDuration = this.buryingPointParams[this.timerItem][this.timerItem + this.firstDuration]

        if (!firstDuration) {
          this.buryingPointParams[this.timerItem][this.timerItem + this.firstDuration] = this.timeLong // 首次时长
        }
        // if (this.dataForm[this.timerItem]) {}
        this.buryingPointParams[this.timerItem][this.timerItem + this.clickNum] += 1 // 点击次数
        this.buryingPointParams[this.timerItem][this.timerItem + this.updatecount] += 1 // 修改总次数

        this.buryingPointParams[this.timerItem][this.timerItem + this.duration] += this.timeLong // 修改总时长

        this.timeLong = 0
        this.hasValChange = false

        console.log('赋值---', this.buryingPointParams[this.timerItem])
      }
    },

    async subMitPoint() {
      let params = {}
      Object.keys(this.buryingPointParams).forEach(item => {
        console.log(item,'11111subMitPointsubMitPointsubMitPoint');
        params = { ...params, ...this.buryingPointParams[item] }
      })

      // 最后设置页面停留时长
      params.pagestop_duration = this.getinterval(this.getnewtime(), this.pagestopTime)
      params.page_duration = params.pagestop_duration
      params.backend_num = this.backend_num
      console.log('埋点提交数据---', params,JSON.stringify(params))
      // pointName 埋点函数名字 配在路由 meta 或 者本页
      const pointName = this.$route.meta.buryingPointName || this.buryingPointName
      console.log(pointName)
      if (!pointName) return
      try {
        await buryingPointName(pointName, params)
        this.isSubMitPoint = false
      } catch (err) {
        this.isSubMitPoint = false
      }
    },

    // 获取当前时间戳（秒）
    getnewtime() {
      return parseInt((new Date().getTime() / 1000).toString())
    },

    // 当前时间-初始时间
    getinterval(nowval, oldval) {
      return nowval - oldval
    },
    confirmClickNum(objname,name,index){
      this.buryingPointParams[objname][name]+=1
      if(index){
        this.$refs.selectPhone[index-1].$el.classList.add('empty-phone')
      }
    }
  }
}
