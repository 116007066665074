import InfoSteps from '@/components/infoSteps.vue'
import buryingPoint from '@/mixins/buryingPoint'
import PopuActionSelect from './baseInfo/components/PopuActionSelect.vue'
import { getBasicInfo, getSubBasicInfo } from '@/api/axios'
import { baseInfoSubmit } from '@/utils/mappingTable.ts'

export default {
  mixins: [buryingPoint],
  components: { InfoSteps, PopuActionSelect },
  watch: {
    dataForm: {
      async handler() {
        let submitFlag = true
        await Object.keys(this.dataForm).forEach((item) => {
          if (!this.dataForm[item] && item !== 'dwell_city' && item !== 'company_city') submitFlag = false
          if(item==='contact_json'){
            this.dataForm[item].forEach(contactItem=>{
              Object.keys(contactItem).forEach(contactItemItem=>{
                if(!contactItem[contactItemItem])submitFlag = false
              })
            })
          }
        })
        this.submitFlag = submitFlag
        console.log(this.submitFlag, this.dataForm, 'this.submitFlag');
        this.$emit('submitFlag', submitFlag)
        if (submitFlag) this.inputEndow()
      },
      deep: true
    }
  },
  data() {
    return {
      submitFlag: false,
      basePlaceholder: ''
    }
  },

  computed: {
    baseInfoSubData: {
      get() {
        return this.$store.state.settings.baseInfoSubData
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'baseInfoSubData',
          value: val
        })
      }
    },

    baseInfoBuryingPointData: {
      get() {
        return this.$store.state.settings.baseInfoBuryingPointData
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'baseInfoBuryingPointData',
          value: val
        })
      }
    }
  },

  mounted() {
    const _t = this
    this.$nextTick(() => {
      // this.inputSetStyle()
      const input = document.getElementsByTagName('input')
      for (let i = 0; i < input.length; i++) {
        input[i].parentNode.onclick = function () {
          if (this.children[0].disabled) {
            // _t.$toast.fail(_t.$t('baseInfo.perfectInfo'))
          }
        }
      }
    })
  },

  methods: {
    // 对性别的提示做单独处理(新增)
    handleSexClick() {
      if (!this.dataForm.whatsapp) {
        // this.$toast.fail(this.$t('baseInfo.perfectInfo'))
      }
    },
    inputSetStyle() {
      const input = document.getElementsByTagName('input')
      for (let i = 0; i < input.length; i++) {
        const parentsNode = input[i].parentNode.parentNode.parentNode

        this.inputOnfocus(input[i], parentsNode) // 获取焦点
        this.inputOnblur(input[i], parentsNode) // 失去焦点
      }
    },

    inputEndow() {
      const input = document.getElementsByTagName('input')
      for (let i = 0; i < input.length; i++) {
        const parentsNode = input[i].parentNode.parentNode.parentNode
        if (input[i].value) {
          parentsNode.classList.add('focus-input')
        }
      }
    },

    inputOnfocus(_t, parentsNode) {
      _t.onfocus = function () {
        this.basePlaceholder = _t.placeholder
        parentsNode.classList.add('focus-input')
        _t.setAttribute('placeholder', '')
      }
    },

    inputOnblur(_t, parentsNode) {
      _t.onblur = function () {
        const className = 'focus-input'
        const reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
        if (!this.value) {
          parentsNode.className = parentsNode.className.replace(reg, ' ') // 移除要删除的类
          _t.setAttribute('placeholder', this.basePlaceholder)
        }
      }
    },
    // 省市区选择完成 (区/省/市)
    areaFinish(county, provinces, city) {
      if (this.$options.name === 'BaseInfo') {
        this.dataForm.dwell_county = county
        this.dataForm.dwell_provinces = provinces
        this.dataForm.dwell_city = city
      } else {
        this.dataForm.company_county = county
        this.dataForm.company_provinces = provinces
        this.dataForm.company_city = city
      }
    },

    // 提交基本信息
    async dataFromSubmit() {
      console.log(this.dataForm)
      const _t = this
      const params = {
        ...this.baseInfoSubData,
        ...this.$refs.componentId.dataForm,
        ...this.dataForm
      }

      if (params.whatsapp.indexOf('63') === 0) {
        params.whatsapp = params.whatsapp.replace(/^63/g, '0')
      }
      // 设置一下埋点数据
      this.setPointData()
      console.log('提交的埋点数据', this.buryingPointParams)
      const subParams = this.setSubData(params)
      console.log('提交的数据', subParams, JSON.stringify(subParams), params.submit_tag + '=====')
      localStorage.setItem('dataFromSubmit', JSON.stringify(subParams))
      localStorage.setItem('buryingPointParams', JSON.stringify(this.buryingPointParams))
      if (params.submit_tag+'' === '3')return
      if (params.submit_tag + '' === '2'){
        this.$H5Object.cancleView()
        return
      } 

      this.$H5Object.checkLocationPermission()
      window.canGoWithdrawPage = () => {
        _t.isAuthSubmit(params,subParams)
      }
      // try {
      //   const { code } = await getSubBasicInfo(subParams) || {}
      //   console.log('code--' + code, '提交类型2为返回提交--' + params.submit_tag)

      //   this.isSubMitPoint = true
      //   if (params.submit_tag === 2) {
      //     await this.$H5Object.cancleView()
      //   } else {
      //     // this.isSubMitPoint = true
      //     // this.$H5Object.submitContact()
      //     this.$router.push({ name: 'Withdrawal' })
      //   }
      // } catch (err) {
      //   console.log(err)
      // }
    },
    // 授权后接口提交
    async isAuthSubmit(params,subParams) {
      console.log('获取权限完成调用接口')
      // if (params.submit_tag === 2) {
      //   await this.$H5Object.cancleView()
      // } else {
      //   this.isSubMitPoint = true
      //   // this.$H5Object.submitContact()
      //   this.$router.push({ name: 'Withdrawal' })
      // }
      try {
        const { code } = await getSubBasicInfo(subParams) || {}
        console.log('code--' + code, '提交类型2为返回提交--' + params.submit_tag)

        // this.isSubMitPoint = true
        // this.$router.push({ name: 'Withdrawal' })
        if (params.submit_tag === 2) {
          await this.$H5Object.cancleView()
        } else {
          this.isSubMitPoint = true
          // this.$H5Object.submitContact()
          this.$router.push({ name: 'Withdrawal' })
        }
      } catch (err) {
        console.log(err)
      }
    },

    // (新增)
    setSubData(params) {
      const obj = {}
      if (params.card_type == 1) {
        params.card_number = 'CRN' + params.card_number
      }
      if (params.card_type == 9) {
        params.card_number = 'PRN' + params.card_number
      }

      Object.keys(params).forEach(item => {
        if (baseInfoSubmit[item]) {
          obj[baseInfoSubmit[item]] = params[item]
        }
      })
      obj.Ukubika_nabantu_abengi.forEach(item => {
        item.mobile_phone_number = item.phone
        item.Full_name = item.name
        item.Ukufumya_nabantu_abengi_relationship = item.relationship
        // delete item.phone
        // delete item.name
        // delete item.relationship
      })

      console.log('-----提交的数据obj-----', obj);
      return obj
    },

    // 获取基本信息
    async getBasicInfo() {
      let dataFromSubmitData = JSON.parse(localStorage.getItem('dataFromSubmit'))
      if (dataFromSubmitData) {
        console.log(JSON.stringify(dataFromSubmitData) + '=====')
        this.$nextTick(() => {
          this.setShowBaseInfo(dataFromSubmitData)
        })
        return
      }
      // const { data } = await getBasicInfo() || {}
      // this.$nextTick(() => {
      //   this.setShowBaseInfo(data)
      // })
    },
    // 回显基本信息
    setShowBaseInfo(data) {
      
      Object.keys(baseInfoSubmit).forEach(item => {
        if (typeof data[baseInfoSubmit[item]] === 'number' || typeof data[baseInfoSubmit[item]] === 'string' && data[baseInfoSubmit[item]]) {
          Object.keys(this.dataForm).forEach(items=>{
            if(items === item){
              this.dataForm[item] = data[baseInfoSubmit[item]] + ''
            }
          })
          // this.dataForm[item] = data[baseInfoSubmit[item]] + ''
        }
      })
      if (this.dataForm.card_type == 1) {
        this.dataForm.card_number = this.dataForm.card_number.replace('CRN', '')
      }
      if (this.dataForm.card_type == 9) {
        this.dataForm.card_number = this.dataForm.card_number.replace('PRN', '')
      }
      // let birthdayStr =this.dataForm.birthday? this.dataForm.birthday.split('-') :''
      // this.currentDate = birthdayStr? new Date(birthdayStr[0],Number(birthdayStr[1])-1 , birthdayStr[2]) : new Date()
      this.currentDate = this.dataForm.birthday ? new Date(this.dataForm.birthday) : new Date()
      this.dataForm.dwell_address = data?.Address || data?.address || ''
      if (!this.dataForm.work_type) this.dataForm.work_type = '1'
      if (data.county) {
        this.dataForm.residential_area = data.county + '/' + data.provinces + (data.city ? '/' + data.city : '')
      }
      console.log(this.dataForm)
      this.getActivationEmailStatus() // 获取一下邮箱激活状态
      this.setShowContact(data)
    },

    // 回显联系人
    setShowContact(data) {
      console.log(JSON.stringify(data.Ukubika_nabantu_abengi) +'反显联系人')
      if (data?.Ukubika_nabantu_abengi.length) {
        data.Ukubika_nabantu_abengi.forEach((item, index) => {
          const obj = {
            'name': item.Full_name,
            'relationship': item.Ukufumya_nabantu_abengi_relationship,
            'phone': item.mobile_phone_number
          }
          this.$set(this.dataForm.contact_json, index, obj)
        })
      }
      this.$refs.componentId.$nextTick(() => {
        this.setShowWorkInfo(data?.Imimonekele_filembo || data || {})
      })
    },

    // 回显工作信息
    setShowWorkInfo(data) {
      console.log(data, 'eee')
      Object.keys(baseInfoSubmit).forEach(item => {
        if (typeof data[baseInfoSubmit[item]] === 'number' || typeof data[baseInfoSubmit[item]] === 'string') {
          if(data[baseInfoSubmit[item]]){
            this.$refs.componentId.dataForm[item] = data[baseInfoSubmit[item]]
          }
        }
      })
      console.log(this.$refs.componentId.dataForm, 'this.$refs.componentId.dataForm')
      if (data.corporate_county) {
        this.$refs.componentId.dataForm.company_region = data.corporate_county + '/' + data.corporate_provinces + (data.corporate_city ? '/' + data.corporate_city : '')
      }
      this.$refs.componentId.isGetData = true
    },
    // 设置埋点信息
    setPointData() {
      this.buryingPointParams.useof_funds.usage_num = this.buryingPointParams.useof_funds.useof_funds_num
      this.buryingPointParams.useof_funds.usage_duration = this.buryingPointParams.useof_funds.useof_funds_duration
      this.buryingPointParams.useof_funds.usage_updatecount = this.buryingPointParams.useof_funds.useof_funds_updatecount

      this.buryingPointParams.dwell_address.address_num = this.buryingPointParams.dwell_address.dwell_address_num
      this.buryingPointParams.dwell_address.address_duration = this.buryingPointParams.dwell_address.dwell_address_duration
      this.buryingPointParams.dwell_address.address_updatecount = this.buryingPointParams.dwell_address.dwell_address_updatecount

      this.buryingPointParams.region = { region_num: 0, region_updatecount: 0, region_duration: 0 }
      
      this.buryingPointParams.region.region_num = this.buryingPointParams.residential_area.residential_area_num
      this.buryingPointParams.region.region_updatecount = this.buryingPointParams.residential_area.residential_area_updatecount
      this.buryingPointParams.region.region_duration = this.buryingPointParams.residential_area.residential_area_duration


      this.buryingPointParams.work_type.wo_num = this.buryingPointParams.work_type.work_type_num
      this.buryingPointParams.work_type.wo_durat = this.buryingPointParams.work_type.work_type_duration
      this.buryingPointParams.work_type.wo_upda = this.buryingPointParams.work_type.work_type_updatecount


      this.buryingPointParams.next_num = { next_num: this.next_num }


      const childBp = this.$refs.componentId.buryingPointParams
      if (childBp) {
        this.buryingPointParams = { ...this.buryingPointParams, ...childBp }

        this.buryingPointParams.years_working.ep_num = this.buryingPointParams.years_working.years_working_num
        this.buryingPointParams.years_working.ep_durat = this.buryingPointParams.years_working.years_working_duration
        this.buryingPointParams.years_working.ep_upda = this.buryingPointParams.years_working.years_working_updatecount
        // 工作年限
        this.buryingPointParams.years_working.yw_num = this.buryingPointParams.years_working.years_working_num
        this.buryingPointParams.years_working.yw_durat = this.buryingPointParams.years_working.years_working_duration
        this.buryingPointParams.years_working.yw_upda = this.buryingPointParams.years_working.years_working_updatecount


        // 行业
        this.buryingPointParams.profession.pr_num = this.buryingPointParams.profession.profession_num
        this.buryingPointParams.profession.pr_durat = this.buryingPointParams.profession.profession_duration
        this.buryingPointParams.profession.pr_upda = this.buryingPointParams.profession.profession_updatecount


        // 月收入
        this.buryingPointParams.monthly_income.mi_num = this.buryingPointParams.monthly_income.monthly_income_num
        this.buryingPointParams.monthly_income.mi_durat = this.buryingPointParams.monthly_income.monthly_income_duration
        this.buryingPointParams.monthly_income.mi_upda = this.buryingPointParams.monthly_income.monthly_income_updatecount

        // 公司名
        this.buryingPointParams.company_name.ep_num = this.buryingPointParams.company_name.company_name_num
        this.buryingPointParams.company_name.ep_durat = this.buryingPointParams.company_name.company_name_duration
        this.buryingPointParams.company_name.ep_upda = this.buryingPointParams.company_name.company_name_updatecount

        // 公司电话
        this.buryingPointParams.company_phone.cp_num = this.buryingPointParams.company_phone.company_phone_num
        this.buryingPointParams.company_phone.cp_durat = this.buryingPointParams.company_phone.company_phone_duration
        this.buryingPointParams.company_phone.cp_upda = this.buryingPointParams.company_phone.company_phone_updatecount

        // 公司地址
        this.buryingPointParams.company_address.ca_num = this.buryingPointParams.company_address.company_address_num
        this.buryingPointParams.company_address.ca_durat = this.buryingPointParams.company_address.company_address_duration
        this.buryingPointParams.company_address.ca_upda = this.buryingPointParams.company_address.company_address_updatecount

        // 周薪
        this.buryingPointParams.salary_way.sw_num = this.buryingPointParams.salary_way.salary_way_num
        this.buryingPointParams.salary_way.sw_durat = this.buryingPointParams.salary_way.salary_way_duration
        this.buryingPointParams.salary_way.sw_upda = this.buryingPointParams.salary_way.salary_way_updatecount

        // 发薪日
        this.buryingPointParams.pay_day.pd_num = this.buryingPointParams.pay_day.pay_day_num
        this.buryingPointParams.pay_day.pd_durat = this.buryingPointParams.pay_day.pay_day_duration
        this.buryingPointParams.pay_day.pd_upda = this.buryingPointParams.pay_day.pay_day_updatecount
      }

      // 联系人1
      this.buryingPointParams.phone_one.cononeph_num = this.buryingPointParams.phone_one.phone_one_num
      this.buryingPointParams.phone_one.cononeph_duration = this.buryingPointParams.phone_one.phone_one_duration
      this.buryingPointParams.phone_one.cononeph_updatecount = this.buryingPointParams.phone_one.phone_one_updatecount

      this.buryingPointParams.relationship_one.conone_num = this.buryingPointParams.relationship_one.relationship_one_num
      this.buryingPointParams.relationship_one.conone_duration = this.buryingPointParams.relationship_one.relationship_one_duration
      this.buryingPointParams.relationship_one.conone_updatecount = this.buryingPointParams.relationship_one.relationship_one_updatecount

      this.buryingPointParams.name_one.cononename_num = this.buryingPointParams.name_one.name_one_num
      this.buryingPointParams.name_one.cononena_duration = this.buryingPointParams.name_one.name_one_duration
      this.buryingPointParams.name_one.cononena_updatecount = this.buryingPointParams.name_one.name_one_updatecount


      // 联系人2
      this.buryingPointParams.phone_two.contwoph_num = this.buryingPointParams.phone_two.phone_two_num
      this.buryingPointParams.phone_two.contwoph_duration = this.buryingPointParams.phone_two.phone_two_duration
      this.buryingPointParams.phone_two.contwoph_updatecount = this.buryingPointParams.phone_two.phone_two_updatecount

      this.buryingPointParams.relationship_two.contwo_num = this.buryingPointParams.relationship_two.relationship_two_num
      this.buryingPointParams.relationship_two.contwo_duration = this.buryingPointParams.relationship_two.relationship_two_duration
      this.buryingPointParams.relationship_two.contwo_updatecount = this.buryingPointParams.relationship_two.relationship_two_updatecount

      this.buryingPointParams.name_two.contwoname_num = this.buryingPointParams.name_two.name_two_num
      this.buryingPointParams.name_two.contwona_duration = this.buryingPointParams.name_two.name_two_duration
      this.buryingPointParams.name_two.contwona_updatecount = this.buryingPointParams.name_two.name_two_updatecount

      // 联系人3
      this.buryingPointParams.phone_three.conthrph_num = this.buryingPointParams.phone_three.phone_three_num
      this.buryingPointParams.phone_three.conthrph_duration = this.buryingPointParams.phone_three.phone_three_duration
      this.buryingPointParams.phone_three.conthrph_updatecount = this.buryingPointParams.phone_three.phone_three_updatecount

      this.buryingPointParams.relationship_three.conthr_num = this.buryingPointParams.relationship_three.relationship_three_num
      this.buryingPointParams.relationship_three.conthr_duration = this.buryingPointParams.relationship_three.relationship_three_duration
      this.buryingPointParams.relationship_three.conthr_updatecount = this.buryingPointParams.relationship_three.relationship_three_updatecount

      this.buryingPointParams.name_three.conthreename_num = this.buryingPointParams.name_three.name_three_num
      this.buryingPointParams.name_three.conthrna_duration = this.buryingPointParams.name_three.name_three_duration
      this.buryingPointParams.name_three.conthrna_updatecount = this.buryingPointParams.name_three.name_three_updatecount


      // this.isSubMitPoint = true
      // this.$router.push({ name: 'Withdrawal' })
    }
  }

}

